/* Root Variables */
:root {
  --primary-color: #0077cc;
  --secondary-color: #66b3ff;
  --background-light: #fff;
  --background-light-secondary: #ddd;
  --background-dark: #333;
  --background-dark-secondary: #c5c4c4;
  --primary-dark-hover: #0077cc;
  --secondary-hover: #0062a2;
  --primary-light-hover: #66b3ff;
  --secondary-light-hover: #fff;
  --blocked-color: #8b8b8bb5;
  --range-color: #98b8e45e;
  --danger-color: #cc0000;
  --danger-color-hover: #a30000;
  --font-color: #333;
  --font-family: "Helvetica Neue", sans-serif;
  --font-size: 16px;
  --line-height: 1.5;
  --border-radius: 4px;
  --max-width: 1200px;
}

/* Global Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* General */
body {
  font-family: var(--font-family);
  font-size: var(--font-size);
  color: var(--font-color);
}




/* ----- Pages ----- */

/* Home.js */


/* Header styles */
header {
  background-color: var(--background-dark);
  color: var(--background-light);
  padding: 10px;
}

header h1 {
  margin: 0;
}

/* List styles */
.listContainer {
  margin-top: 20px;
}

.listContainer .tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.listContainer .tabs button {
  background-color: var(--background-light-secondary);
  border: none;
  color: var(--background-dark);
  padding: 10px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
}

.listContainer .tabs button.active {
  background-color: var(--background-dark);
  color: var(--background-light);
}

.listContainer .request {
  border: 1px solid var(--background-light-secondary);
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}

.listContainer .request h2 {
  margin-top: 0;
}

.listContainer .request p {
  margin: 0;
}

/* Button styles */
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.buttonContainer button {
  background-color: var(--background-dark);
  border: none;
  color: var(--background-light);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: var(--background-light);
  border-radius: 5px;
  padding: 0px;
  position: relative;
}

.modal-content .close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  right: 0;
  font-size: 6vh;
  width: 60px;
  height: 60px;
  font-weight: bold;
  color: var(--background-dark-secondary);
  cursor: pointer;
}

.formHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.formHeader h3 {
  margin-left: 1vw;
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
  }

  .listContainer .tabs {
    flex-direction: column;
    align-items: center;
  }

  .listContainer .tabs button {
    margin: 10px 0;
  }

  .buttonContainer {
    flex-direction: column;
    align-items: center;
  }

  .buttonContainer button {
    margin: 10px 0;
  }

  .modal-content {
    background-color: var(--background-light);
    border-radius: 5px;
    padding: 0px;
    position: relative;
    width: 90%;
  }
}

@media (max-height: 520px) {
  .modal-content {
    background-color: var(--background-light);
    border-radius: 5px;
    padding: 0px;
    position: relative;
    height: 90%;
    width: 70%;
  }
}



/* X--<Home/>--X */

/* Login.js */

.card-container {
  position: absolute;
  top: 0; /* Add top property */
  width: 100%;
  height: 100%;
  padding: 2%;
  background-color: var(--background-light-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll; /* Add overflow property */
}

.card {
  width: 40%;
  min-height: 80%; /* Add min-height property */
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  overflow: auto; /* Add overflow property */
}

.card.signup-card {
  overflow: hidden;
}

.card-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.login-card,
.signup-card,
.welcome-card {
  flex: 1;
  overflow: hidden;
}

.card-body {
  width: 75%;
  margin-bottom: 100px;
}

.card h4 {
  font-size: 5vh;
  padding-top: 10vh;
  margin-bottom: 5vh;
}

.login-title, .signup-title {
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
  text-decoration-thickness: 0.5vh;
  text-underline-offset: 0.5vh;
}

h4.signup-title {
  padding-top: 5vh;
}

form {
  display: flex;
  flex-direction: column;
}

.nameContainer {
  display: flex;
  flex-direction: row;
}

.nameContainer .form-input.firstName {
  margin-right: 10px;
}

.nameContainer .form-input.lastName {
  margin-left: 10px;
}

hr {
  margin-bottom: 20px;
  margin-top: 10px;
}

.signup-button.alt {
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  background-color: transparent;
  text-decoration: none;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-button.alt:hover {
  background-color: var(--primary-color);
  color: white;
}

button {
  background: transparent;
  border: 0;
  cursor: pointer;
}

.control {
  border: 0.5px solid var(--background-dark-secondary);
  border-radius: 400px;
  outline: none;
  width: 100%;
  height: 10vh;
  padding: 0 4vh;
  background: var(--background-light-secondary);
  color: black;
  margin: 2vh 0;
  font-family: inherit;
  font-size: 2.8vh;
  transition: 0.4s;
}

button.control {
  background: var(--primary-color);
  border: 0px;
  color: white;
}

button:hover {
  background: var(--primary-color);
}

.resetContainer {
  width: 100%;
  display: flex;
}

.reset-button {
  display: block;
  width: 100%;
  display: flex;
  margin-top: -10px;
  margin-left: 30px;
  color: var(--background-dark);
}

.welcome-card {
  background: var(--primary-color);
}

.welcome-card h4 {
  color: white;
}

.welcome-card p, .card-body p {
  text-align: center;
  font-size: 2vh;
}

.welcome-card .signup-button, .login-button {
  color: white;
  border: 2px solid white;
  border-radius: 200px;
  text-decoration: none;
  padding: 2.8vh 10vw;
  margin-top: 2vh;
  font-size: 2.8vh;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.welcome-card .signup-button:hover, .login-button:hover {
  background-color: white;
  color: var(--primary-color);
}

@media only screen and (max-height: 659px) {
  .card-container {
      align-items: flex-start;
  }
}

@media only screen and (max-width: 847px) {
  .card {
    width: 75%;
    min-height: 75%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .control {
    font-size: 4.8vw;
  }

  .welcome-card {
    display: none;
  }

  .card.signup-card {
    overflow: scroll;
  }
}

@media only screen and (max-width: 500px) {
  .card-container {
      padding: 0px;
  }

  .card-wrapper {
      min-width: 100%;
      min-height: 100%;
  }

  .card.signup-card {
    overflow: scroll;
  }
  
  h4.signup-title {
    padding-top: 10vh;
  }

  .nameContainer {
    flex-direction: column;
  }

  .nameContainer .form-input.firstName {
  margin-right: 0px;
}

.nameContainer .form-input.lastName {
  margin-left: 0px;
}

}

@media only screen and (min-width: 1500px) {
  .card-wrapper {
      height: 100%;
  }
}
/* X--</>--X */



/* Search Page */

.userListComponent {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.userListComponent h3 {
  font-size: 32px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 16px;
  text-align: center;
}

.userListComponent input {
  width: 100%;
  border: 1px solid var(--primary-color);
  background: var(--background-light);
  color: var(--primary-color);
  font-weight: bold;
  font-size: 16px;
  padding: 12px 16px;
  border-radius: 24px;
  text-align: center;
}

.userListComponent input::placeholder {
  color: var(--primary-color);
}

.userListComponent input:focus {
  border-color: var(--secondary-hover);
  outline: none;
}

.userListComponent .userContainer {
  margin-top: 16px;
}

.userListComponent .user {
  border-bottom: 1px solid var(--background-dark-secondary);
  padding: 16px;
  text-align: center;
}

.userListComponent .user h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: center;
  color: var(--primary-color);
}

.userListComponent .user p {
  font-size: 14px;
  margin-bottom: 16px;
  text-align: center;
}

.userListComponent .user.no-users {
  padding: 16px 0;
}

.userListComponent .user.no-users h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
}

.userListComponent .user.no-users p {
  font-size: 16px;
  margin-bottom: 0;
  text-align: center;
}

@media (max-width: 768px) {
  .userListComponent {
    width: 100%;
  }

  .userListComponent h3 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 12px;
    text-align: center;
  }

  .userListComponent input {
    width: 100%;
    border: 1px solid var(--primary-color);
    background: var(--background-light);
    color: var(--primary-color);
    font-weight: bold;
    padding: 10px 12px;
    border-radius: 24px;
    text-align: center;
  }

  .userListComponent input::placeholder {
    color: var(--primary-color);
  }

  .userListComponent input:focus {
    border-color: var(--primary-dark-hover);
    outline: none;
  }

  .userListComponent .userContainer {
    margin-top: 12px;
  }

  .userListComponent .user {
    border-bottom: 1px solid var(--background-dark-secondary);
    padding: 12px;
    text-align: center;
  }

  .userListComponent .user h4 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
    text-align: center;
  }

  .userListComponent .user p {
    font-size: 12px;
    margin-bottom: 12px;
    text-align: center;
  }

  .userListComponent .user.no-users {
    padding: 12px 0;
  }

}

/* X--</>--X */


/* User Detail Page */

.userDetailComponent {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.userDetailComponent .requestListComponent {
  min-width: 90%;
}

.userDetailComponent > p {
  margin: 10px;
  padding: 10px;
  min-width: 200px;
  border: 2px solid var(--primary-color);
  border-radius: 40px;
}

.role-edit-button:hover {
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--background-light);
}

.roleForm .formHeader {
  margin: 10px;
  margin-top: 0px;
}

.roleForm form {
  margin: 10px;
  display: flex;
  align-items: center;
}

.roleForm form div {
  margin: 20px;
}

.roleForm form div h4{
  text-decoration: underline;
}

.roleForm form div h5{
  margin-bottom: 20px;
}

.roleForm form button {
  background-color: var(--primary-color);
  border-radius: 20px;
  width: 30%;
  height: 40px;
}


.access-denied h3 {
  margin-top: 20px;
  text-align: center;
}

/* X--</>--X */

/* XXXXXXXXXXXXXXXXX */





/* ----- Components ----- */


/* Request List */

.requestListComponent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 0px;
  max-width: 100%;
}

.requestContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 0px;
  width: 100%;
}

.no-request-container {
  position: relative;
  top: 100px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--primary-color);
  z-index: -10;
}

.no-request-container img {
  position: relative;
  top: 5px;
  width: 400px;
  z-index: -10;
}

.request {
  background-color: var(--background-light-secondary);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  padding: 1rem;
  text-align: center;
  width: 80%;
}

.request h3 {
  margin-bottom: 0;
}

.request p {
  margin: 0;
}

.request .request-date-container {
  flex: 1;
  text-align: center;
}

.request .status-container {
  flex: 1;
}

.request .created-date-container {
  flex: 1;
  text-align: center;
}

.request .delete-button-container {
  margin-top: 0;
  text-align: center;
  flex: 1;
}

.request a {
  color: var(--primary-color);
  text-decoration: none;
}

.request .deleteBtn {
  background-color: var(--primary-color);
  border: none;
  border-radius: 5px;
  color: var(--background-light);
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease;
}

.request .deleteBtn:hover {
  background-color: var(--secondary-hover);
}


@media only screen and (max-width: 769px) {
  .requestListComponent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 800px;
}

.request {
  background-color: var(--background-light-secondary);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem;
  padding: 1rem;
  text-align: center;
  width: 80%;
}

.request h3 {
  margin-bottom: 0;
}

.request p {
  margin: 0;
}

.request .view-button-container, .status-controller, .request-date-container, .created-date-container {
  margin-bottom: 1rem;
}

.request .delete-button-container {
  margin-top: 1rem;
  text-align: center;
}

.request a {
  color: var(--primary-color);
  text-decoration: none;
}

.request .deleteBtn {
  background-color: var(--primary-color);
  border: none;
  border-radius: 5px;
  color: var(--background-light);
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease;
}

.request .deleteBtn:hover {
  background-color: var(--secondary-hover);
}


}

/* X--</>--X */



/* Single Request */

.single-request-main {
  height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.single-request-main h3 {
  text-align: center;
}

.textContainer {
  text-align: center;
  background-color: var(--primary-color);
  color: var(--secondary-light-hover);
  padding: 20px;
  width:fit-content;
  margin: 10px;
  border-radius: 50px;
}

/* X--</>--X */




/* Header.js */

.header {
  background-color: var(--primary-color);
  color: var(--background-light);
  padding: 1rem;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logout {
  font-size: 2vh;
  color: white;
  text-decoration: underline;
  text-decoration-color: white;
  text-decoration-thickness: 2px;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.btn-primary {
  background-color: var(--background-light);
  color: var(--primary-color);
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background-color: var(--secondary-hover);
  color: var(--background-light);
  cursor: pointer;
}


/* X--</>--X */



/* ButtonContainer */

.buttonContainer {
  margin: 0px;
  flex-direction: column;
  align-items: center;
}

.buttonContainer .create-request-button {
  width: auto;
  height: auto;
  padding: 20px;
  border-radius: 100px;
  font-size: 24px;
  margin-top: 20px;
}

.buttonContainer .create-request-button:hover {
  background-color: var(--primary-dark-hover);
}

.dateBlockContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
}

.dateBlockContainer button {
  margin-top: 10px;
}

.users-link-button {
  background-color: var(--background-dark);
  color: var(--background-light);
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
}

/* X--</>--X */

/* Controller */

div.statusController.requestItem {
  width: 20vw;
  margin: 5px;
}

button.approve-button, button.deny-button{
  border-radius: 20px;
  border: 2px solid var(--primary-color);
  width: 4vw;
  min-width: 100px;
  padding: 10px;
  height: 100%;
  margin: 0.5vh 1vw;
}

button.approve-button:hover, button.deny-button:hover {
  border-radius: 20px;
}


button.approve-button:hover {
  background-color: rgb(126, 220, 139);
}

button.deny-button:hover {
  background-color: rgb(221, 75, 75);
}



@media only screen and (max-width: 769px) {
  div.statusController.requestItem {
    width: 100%;
    margin-bottom: 1rem;
  }
}

/* X--</>--X */

/* XXXXXXXXXXXXXXXXX */





/* Status */

.pending {color: grey;}
.denied {color: red;}
.approved {color: green;}




/* DateRangePicker */

.DateRangePickerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.date-range-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Arial, sans-serif;
  color: var(--background-dark);
  width: 100%;
  margin: 0 auto;
}

.calendar {
  position: relative;
  width: 99%;
  border: 1px solid var(--background-dark-secondary);
  border-radius: 4px;
  background-color: var(--background-light);
  padding: 16px;
}

div.calendar-day.blocked.current-day {
  text-decoration: solid;
  text-decoration-line: underline;
  text-decoration-color: var(--primary-dark-hover);
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
}

.month-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

button.prevButton:hover, button.nextButton:hover {
  background-color: var(--primary-dark-hover);
}

.weekday-header {
  display: flex;
  justify-content: space-around;
  margin-bottom: 8px;
}

.weekday-header > * {
  width: 52.28px;
  text-align: center;
}

.calendar-row {
  display: flex;
  justify-content: center;
  height: 40px;
}

.calendar-day {
  display: inline-block;
  width: 100%;
  /* width: calc(100% / 7); */
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  white-space: normal; /* added */
  margin: 0px;
}

.calendar-day:hover {
  background-color: var(--background-light-secondary);
}

.calendar-day.blocked {
  color: var(--blocked-color);
  text-decoration-line: line-through;
  text-decoration: line-through;
  cursor: not-allowed;
}

.calendar-day.selected {
  background-color: var(--primary-dark-hover);
  color: var(--background-light);
}

.calendar-day.in-range {
  background-color: var(--range-color);
  color: var(--primary-dark-hover);
  border-radius: 10px;
  opacity: 1;
}

.confirm-request-button {
  width: 50%;
  margin: 10px;
}

.confirm-request-button:hover {
  background-color: var(--primary-dark-hover);
}

.confirm-request-button:disabled {
  background-color: var(--blocked-color);
  cursor: not-allowed;
}

.confirm-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.confirm-date {
  text-align: center;
}

.confirm-date.selected {
  color: green;
}

@media (max-height: 520px) {
  .date-range-picker {
    height: 80%;
  }
  .calendar {
  height: 200px;
  overflow-y: scroll;
  }

}

.first {
  background-color: red;
}

.second {
  background-color: green;
}

/* ********** */

.dateBlockerModal {
  width: 100%;
}

.dateBlockerModal .modal-content {
  background-color: var(--background-light);
  border-radius: 5px;
  padding: 0px;
  position: relative;
  width: 40%;
  max-width: 350px;
}

.dateBlocker {
  width: 100%;
  max-height: 60vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.dateBlocker form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.dateBlocker form input[type="date"] {
  width: 100%;
  max-width: 20rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1);
  font-size: 1.5rem;
}

.dateBlocker form button[type="submit"] {
  width: 100%;
  max-width: 20rem;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  border: none;
  border-radius: 0.25rem;
  color: var(--background-light);
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.dateBlocker form button[type="submit"]:hover {
  background-color: var(--secondary-hover);
}

.blockedDates {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 20rem;
  margin-bottom: 0.5rem;
}

.blockedDates span {
  margin-right: 1rem;
  font-size: 1rem;
}

.blockedDates button {
  padding: 0.5rem 1rem;
  background-color: var(--danger-color);
  border: none;
  border-radius: 0.25rem;
  color: var(--background-light);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.blockedDates button:hover {
  background-color: var(--danger-color-hover);
}

@media screen and (max-width: 768px) {
  .dateBlocker form input[type="date"] {
      max-width: 15rem;
    }

  .dateBlockerModal .modal-content {
    background-color: var(--background-light);
    border-radius: 5px;
    padding: 0px;
    position: relative;
    width: 90%;
  }
}


/* Password Reset */


.reset-password-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background-light-secondary)DDD;
}

.go-back {
  position: absolute;
  top: 20px;
  left: 20px;
  text-decoration: none;
  font-size: 20px;
  scale: 2;
  font-weight: 900;
  rotate: 180deg;
  color: var(--primary-color);
}

.reset-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 20px;
  background-color: var(--background-light);
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
}

.reset-password-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
  color: var(--primary-color);
}

.reset-password-input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid var(--background-light-secondary);
  border-radius: 4px;
  font-size: 16px;
  outline: none;
}

.reset-password-input:focus {
  border: 2px solid var(--primary-color);
}

.reset-password-error {
  color: red;
  margin-bottom: 10px;
}

.reset-password-button {
  background-color: var(--primary-color);
  color: var(--background-light);
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.reset-password-button:hover {
  background-color: var(--secondary-hover);
}

.confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirmation-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 20px;
  background-color: var(--primary-color);
  color: var(--background-light);
  width: 64px;
  height: 64px;
}

.confirmation-icon::before {
  content: "\2713";
  font-weight: 900;
  font-size: 32px;
}




/* Loading Spinner */

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid var(--background-dark-secondary); /* Light grey */
  border-top: 10px solid var(--background-dark); /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

